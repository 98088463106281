@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Martin';
        src: url('./fonts/Martin-Regular.ttf');
    }
    @font-face {
        font-family: 'Manrope';
        src: url('./fonts/Manrope-Regular.ttf');
    }
    @font-face {
        font-family: 'Manrope';
        src: url('./fonts/Manrope-Bold.ttf');
        font-weight: 700
    }
    html {
        @apply bg-soft-white;
    }
    .candidates-column {
        @apply grid gap-1 justify-center;
        grid-template-columns: repeat(auto-fit, 40px);
        grid-template-rows: 40px;
    }
    .candidates-column.single {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    .presidential-column {
        @apply grid grid-cols-1 gap-6 justify-center;
        grid-template-columns: repeat(auto-fit, 112px);
        grid-template-rows: 1fr;
    }
}
